import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {MatOptionSelectionChange} from '@angular/material/core';
import {Suite} from 'app/fragment/suite';
import {DocumentFragment, DocumentInformationType} from 'app/fragment/types';
import {DocumentService} from 'app/services/document.service';
import {UserService} from 'app/services/user/user.service';
import {UUID} from 'app/utils/uuid';
import {environment} from 'environments/environment';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ErrorCode, Logger} from '../../error-handling/services/logger/logger.service';
import {SearchableDocument, SearchDocumentsService, SearchResult} from '../search-documents.service';
import {DocumentsSearchType} from './documents-search-types';

@Component({
  selector: 'cars-document-selector',
  templateUrl: './document-selector.component.html',
  styleUrls: ['./document-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentSelectorComponent implements OnInit, OnDestroy {
  public readonly tooltipDelay: number = environment.tooltipDelay;

  @Input() public set initialSelection(initialSelection: DocumentFragment) {
    this._initialDocumentSelection = initialSelection;
    this.resetFilter();
    this.cd.markForCheck();
  }
  @Input() public set setSelection(selection: SearchableDocument) {
    this.selectedDocumentDisplayString = this.documentDisplayString(selection);
    this.resetFilter();
    this.cd.markForCheck();
  }
  @Input() public searchPlaceholder: string = 'Search';
  @Input() public searchType: DocumentsSearchType;
  @Input() public disabled: boolean = false;
  @Input() public showClearButton: boolean = false;

  @Output() public onSelect: EventEmitter<SearchableDocument> = new EventEmitter();

  public documents: SearchableDocument[] = [];

  public filterTerm: string;
  public filterTermChanged: Subject<string> = new Subject<string>();
  private selectedDocumentDisplayString: string;
  private _initialDocumentSelection: DocumentFragment = null;

  private _subscriptions: Subscription[] = [];

  constructor(
    private searchDocumentsService: SearchDocumentsService,
    private _documentService: DocumentService,
    private _userService: UserService,
    private cd: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    if (!!this.searchType) {
      this.filterDocuments('');
      this.resetFilter();
      this._subscriptions.push(
        this.filterTermChanged.pipe(debounceTime(200), distinctUntilChanged()).subscribe((debounced) => {
          this.filterDocuments(debounced);
        })
      );
    }
  }

  public ngOnDestroy(): void {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  /**
   * Select the published document to display in the left hand pad.
   *
   * @param event    {MatOptionSelectionChange} The MatOptionSelectionChange event which caused this document to be selected.
   * @param document {DocumentFragment}         The document that was selected.
   */
  public onDropdownSelection(event: MatOptionSelectionChange, selectedDocument: SearchableDocument): void {
    // A selection from a mat-option in a mat-autocomplete will emit two events, it selects the new option and then deselects
    // the old one. This check ensures that we are not sending the document from the deselecting event.
    if (event.isUserInput) {
      this.onSelect.emit(selectedDocument);
      this.selectedDocumentDisplayString = this.documentDisplayString(selectedDocument);
    }
  }

  /**
   * Clears the selected document from filter and emits a null value.
   */
  public clearSelectedDocument(): void {
    this.onSelect.emit(null);
    this.selectedDocumentDisplayString = null;
    this.filterTerm = null;
    this.cd.markForCheck();
  }

  public onNgModelChange(filterTerm: string) {
    this.filterTermChanged.next(filterTerm);
  }

  public searchLabelText(): string {
    switch (this.searchType) {
      case DocumentsSearchType.CHANGELOG:
        return 'Published document:';
      case DocumentsSearchType.SECTION_REFERENCES:
      case DocumentsSearchType.GLOBAL_REFERENCE:
      case DocumentsSearchType.CLAUSE_REFERENCES:
      case DocumentsSearchType.SECTIONS:
      default:
        return '';
    }
  }

  public documentDisplayString(document: SearchableDocument): string {
    const documentCode: string = document.DOCUMENT_CODE || document.SHW_DOCUMENT_CODE;
    return this.getDisplayString(documentCode, document.TITLE);
  }

  /**
   * Update the filteredDocuments array depending on the documentFilter.
   */
  private filterDocuments(filterTerm: string): void {
    this.filterTerm = filterTerm;

    const suite: Suite = this._documentService.getSelected().suite;
    const userId: UUID = this._userService.getUser().id;

    switch (this.searchType) {
      case DocumentsSearchType.CHANGELOG:
        this._handleSearchResponse(this.searchDocumentsService.changelog(this.filterTerm), 'changelog-error');
        break;

      case DocumentsSearchType.SECTION_REFERENCES:
        this._handleSearchResponse(
          this.searchDocumentsService.sectionReferences(this.filterTerm),
          'internal-reference-error'
        );
        break;

      case DocumentsSearchType.CLAUSE_REFERENCES:
        this._handleSearchResponse(
          this.searchDocumentsService.clauseReferences(this.filterTerm),
          'internal-reference-error'
        );
        break;

      case DocumentsSearchType.GLOBAL_REFERENCE:
        this._handleSearchResponse(
          this.searchDocumentsService.globalReferenceCarsLink(this.filterTerm),
          'reference-error'
        );
        break;
      case DocumentsSearchType.SECTIONS:
        const currentDocumentId = this._documentService.getSelected().id;
        this._handleSearchResponse(
          this.searchDocumentsService.sections(suite, userId, this.filterTerm, currentDocumentId),
          'search-error'
        );
        break;
    }
  }

  /**
   * Helper method to set the list of documents given a search request or handle errors.
   */
  private _handleSearchResponse(searchRequest: Promise<SearchResult<SearchableDocument>>, errorKey: ErrorCode): void {
    searchRequest
      .then((res: SearchResult<SearchableDocument>) => {
        this.documents = res.page;
        this.cd.markForCheck();
      })
      .catch((err) => Logger.error(errorKey, 'Failed to filter documents', err));
  }

  /**
   * Reset the document filter to the current document.
   */
  public resetFilter(): void {
    this.filterTerm = this.selectedDocumentDisplayString || this.getInitialSelectionDisplayString();
  }

  private getInitialSelectionDisplayString() {
    if (!this._initialDocumentSelection) {
      return '';
    }
    const documentCode: string =
      this._initialDocumentSelection.documentCode ||
      this._initialDocumentSelection.getInformation(DocumentInformationType.SHW_DOCUMENT_CODE)?.value;
    return this.getDisplayString(documentCode, this._initialDocumentSelection.title);
  }

  private getDisplayString(documentCode: string, title: string): string {
    return !!documentCode ? `${documentCode}: ${title}` : title;
  }

  /**
   * Empty the document filter.
   */
  public emptyFilter(): void {
    this.filterDocuments('');
  }
}
