<div class="container">
  <h2 matDialogTitle>Clone section</h2>
  <p>
    Use this feature to clone a section from another CARS document. You will only see documents that you have access to
    within CARS. The cloned section will always be added to the bottom of this document's section list. The source
    document will not be affected in any way.
  </p>
  <div class="section-clone">
    <cars-document-selector
      [searchType]="DocumentsSearchType.SECTIONS"
      [searchPlaceholder]="searchPlaceholder"
      (onSelect)="getSectionsFromSelectedSourceDocument($event)"
      [disabled]="disableDocumentSelector()"
    ></cars-document-selector>

    <mat-progress-bar *ngIf="loadingSections" mode="indeterminate"></mat-progress-bar>

    <mat-select
      multiple
      placeholder="Select a section"
      [disabled]="disableSectionSelector()"
      (selectionChange)="handleSelectSection($event.value)"
    >
      <ng-container *ngFor="let section of sections">
        <mat-option [value]="section" [attr.aria-label]="'section' + section.index + ' ' + section.title">
          {{ section.index }}. {{ section.title }}
        </mat-option>
      </ng-container>
    </mat-select>
    <mat-hint *ngIf="!loadingSections && selectedSourceDocument && !sections?.length">
      <mat-error>No sections available that can be cloned within the selected document</mat-error>
    </mat-hint>

    <ng-container *ngIf="showDisplayPopupText()">
      <div class="popup-text">
        <p class="{{ popupTextClass() }}">{{ popupTextToDisplay() }}</p>
      </div>
    </ng-container>
  </div>
  <div mat-dialog-actions>
    <button
      mat-raised-button
      color="primary"
      (click)="clone()"
      [disabled]="disableCloneButton()"
      aria-label="Clone section"
      carsAnalytics="{{ 'clone section' }}"
      enableDefaultFocus="true"
    >
      Clone
    </button>

    <button
      mat-button
      mat-raised-button
      mat-dialog-close
      color="basic"
      [attr.aria-label]="'close clone section'"
      [disabled]="cloning"
    >
      Close
    </button>
  </div>
</div>
<mat-progress-bar class="clone-progress-bar" *ngIf="cloning" mode="indeterminate"></mat-progress-bar>
