import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatOptionModule} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {PrivacyFooterModule} from 'app/documents/privacy-footer/privacy-footer.module';
import {CarsErrorHandlingModule} from 'app/error-handling/cars-error-handling.module';
import {CarsPermissionPipesModule} from 'app/permissions/pipes/cars-permission-pipes.module';
import {DocumentSelectorModule} from 'app/search/document-selector/document-selector.module';
import {VersionTagTypeToDisplayNameModule} from 'app/utils/pipes/version-tag-type-to-display-name.module';
import {SectionCloneComponent} from './section-clone.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    MatOptionModule,
    FormsModule,
    MatButtonModule,
    CarsErrorHandlingModule,
    MatProgressBarModule,
    VersionTagTypeToDisplayNameModule,
    CommonModule,
    DocumentSelectorModule,
    MatSnackBarModule,
    MatTableModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatProgressBarModule,
    CarsPermissionPipesModule,
    PrivacyFooterModule,
  ],
  declarations: [SectionCloneComponent],
  exports: [SectionCloneComponent],
})
export class SectionCloneModule {}
