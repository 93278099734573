<div class="box">
  <div class="title">Refresh references</div>
  <p>
    References to sections, clauses and WSR references added to your document do not automatically update. To ensure
    that the lastest indexing, reference numbers and/or titles are included within your document, please click the
    “Refresh references” button provided below.
  </p>
  <button
    mat-raised-button
    color="primary"
    (click)="refreshDocumentReferences()"
    matTooltip="Refresh all clause, section and WSR references within this document"
    [matTooltipShowDelay]="tooltipDelay"
    aria-label="Refresh all clause, section and WSR references within this document"
    [disabled]="loading"
    enableDefaultFocus
  >
    Refresh references
  </button>
  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
</div>
