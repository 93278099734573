import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Suite} from 'app/fragment/suite';
import {ClauseType, DocumentInformationFragment, DocumentInformationType, SectionType} from 'app/fragment/types';
import {SectionGroupType} from 'app/fragment/types/section-group-type';
import {PermissionASTNode} from 'app/permissions/types/permission-ast-node';
import {deserialisePermissionASTNode} from 'app/permissions/types/permission-deserialiser';
import {CarsAction} from 'app/permissions/types/permissions';
import {BaseService} from 'app/services/base.service';
import {RichTextType} from 'app/services/rich-text.service';
import {SidebarStatus} from 'app/sidebar/sidebar-status';
import {UUID} from 'app/utils/uuid';
import {environment} from 'environments/environment';
import {ActiveToolbarFunctions} from './active-toolbar-functions';
import {EnabledToolbarFunctions} from './enabled-toolbar-functions';

export interface ClauseTypeConfiguration {
  clauseType: ClauseType;
  displayName: string;
  isDefault: boolean;
}

export interface SectionTypeConfiguration {
  sectionType?: SectionType;
  sectionGroupType?: SectionGroupType;
  displayName: string;
}

export interface ClauseStylingConfiguration {
  clauseType: ClauseType;
  styling: ClauseStyle[];
  fontSizePx?: number;
  paddingTopPx?: number;
}

export enum ClauseStyle {
  BOLD = 'BOLD',
  ITALICS = 'ITALICS',
  UNDERLINE = 'UNDERLINE',
}

export interface DocumentInformationField {
  fieldType: FieldType;
  carsAction?: CarsAction;
  documentInformationType?: DocumentInformationType;
  enumName?: string;
  titleText?: string;
  hintText?: string;
  valueDisplayName?: DocInfoValueDisplayNameType;
  pattern?: string;
  patternErrorText?: string;
}

export interface DocumentInformationItem {
  documentInformationField: DocumentInformationField;
  documentInformationFragment?: DocumentInformationFragment;
}

export enum FieldType {
  FREE_TEXT = 'FREE_TEXT',
  FREE_TEXT_MULTILINE = 'FREE_TEXT_MULTILINE',
  ENUM = 'ENUM',
  MULTI_SELECT = 'MULTI_SELECT',
  DOCUMENT_HEALTH = 'DOCUMENT_HEALTH',
  JIRA_WORKFLOW = 'JIRA_WORKFLOW',
  NON_EDITABLE = 'NON_EDITABLE',
  DOCUMENT_LINKAGE = 'DOCUMENT_LINKAGE',
  CORE_DOCUMENT_LINKAGE = 'CORE_DOCUMENT_LINKAGE',
  ADMINISTRATION = 'ADMINISTRATION',
  IMAGE_UPLOAD = 'IMAGE_UPLOAD',
}

export enum DocInfoValueDisplayNameType {
  SUITE = 'SUITE',
  CATEGORY_OF_CHANGE = 'CATEGORY_OF_CHANGE',
  DOCUMENT_CHANGES = 'DOCUMENT_CHANGES',
}

export interface DocumentMetricsConfigItem {
  key: DocumentMetricsConfigItemType;
  singularDescription: string;
  pluralDescription: string;
  guidanceText: string; // This replaces the section breakdown of the metric if defined
  iconName: string;
  isIconSvg: boolean;
}

export enum DocumentMetricsConfigItemType {
  ERRORS = 'ERRORS',
  WARNINGS = 'WARNINGS',
  NO_BACKGROUNDS_ON_NORMATIVE_CLAUSE = 'NO_BACKGROUNDS_ON_NORMATIVE_CLAUSE',
  NO_BACKGROUNDS_ON_APPENDIX_CLAUSE = 'NO_BACKGROUNDS_ON_APPENDIX_CLAUSE',
  NO_ALT_TEXT = 'NO_ALT_TEXT',
  NO_CAPTIONS = 'NO_CAPTIONS',
  UNRESOLVED_DISCUSSIONS = 'UNRESOLVED_DISCUSSIONS',
  WITHDRAWN_REF_WITHOUT_YEAR_OF_ISSUE = 'WITHDRAWN_REF_WITHOUT_YEAR_OF_ISSUE',
  INVALID_CLAUSE_LINKS = 'INVALID_CLAUSE_LINKS',
  INTERNAL_SECTION_AND_WSR_REFERENCES_TO_UPDATE = 'INTERNAL_SECTION_AND_WSR_REFERENCES_TO_UPDATE',
  INTERNAL_SECTION_AND_WSR_REFERENCES_TARGET_FRAGMENT_DELETED = 'INTERNAL_SECTION_AND_WSR_REFERENCES_TARGET_FRAGMENT_DELETED',
  INTERNAL_CLAUSE_REFERENCES_TO_UPDATE = 'INTERNAL_CLAUSE_REFERENCES_TO_UPDATE',
  INTERNAL_CLAUSE_REFERENCES_TARGET_FRAGMENT_DELETED = 'INTERNAL_CLAUSE_REFERENCES_TARGET_FRAGMENT_DELETED',
  WSR_MAPPING = 'WSR_MAPPING',
}

export enum ToolbarItemType {
  RICH_TEXT,
  CLAUSE_TYPE_SELECTOR,
  CREATE_TABLE,
  UPLOAD_IMAGE,
  SUGGESTION,
  REORDER_CLAUSES,
  STANDARD_FORMAT_REQUIREMENT,
  SPECIFIER_INSTRUCTION,
}

export interface ToolbarItem {
  toolbarItemType: ToolbarItemType;
  tooltipText: string;
  ariaLabel: string;
  itemKey: string;
  enabled: (...args: any[]) => boolean;
  active?: (...args: any[]) => boolean;
  icons?: string[];
  richTextType?: RichTextType;
  richTextArguments?: any[];
}

export interface SidebarItem {
  sidebarItemType: SidebarStatus;
  icon: string;
  isIconSvg: boolean;
  displayCarsAction: CarsAction;
}

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService extends BaseService {
  private readonly _baseUrl: string = `${environment.apiHost}/configuration`;

  private _cachedClauseTypes: Record<string, Promise<ClauseTypeConfiguration[]>> = {};

  private _cachedSectionTypes: Record<string, Promise<SectionTypeConfiguration[]>> = {};

  private _cachedClauseStyling: Record<string, Promise<ClauseStylingConfiguration[]>> = {};

  private _cachedDocumentInformationFields: Partial<Record<Suite, Promise<DocumentInformationField[]>>> = {};

  private _cachedDocumentMetricsConfigItems: Partial<Record<Suite, Promise<DocumentMetricsConfigItem[]>>> = {};

  private _cachedPermissions: Map<CarsAction, PermissionASTNode> = new Map();

  private _cachedToolbarItems: Partial<Record<Suite, Promise<ToolbarItem[]>>> = {};

  private _cachedSidebarItems: Partial<Record<Suite, Promise<SidebarItem[]>>> = {};

  constructor(protected _snackbar: MatSnackBar, private _http: HttpClient) {
    super(_snackbar);
  }

  public getClauseTypesForSectionId(sectionId: UUID): Promise<ClauseTypeConfiguration[]> {
    let cached: Promise<ClauseTypeConfiguration[]> = this._cachedClauseTypes[sectionId.value];

    if (!cached) {
      cached = this._http
        .get<any[]>(`${this._baseUrl}/clause-types/${sectionId.value}`)
        .toPromise()
        .then((result: any[]) => {
          return result.map((value: any) => {
            return {
              clauseType: ClauseType[value.clauseType as string],
              displayName: value.displayName,
              isDefault: value.isDefault,
            } as ClauseTypeConfiguration;
          });
        })
        .catch((error: any) => {
          this._handleError(error, 'Failed to fetch clause type configuration.', 'configuration-error');
          return Promise.reject(error);
        });

      this._cachedClauseTypes[sectionId.value] = cached;
    }

    return cached;
  }

  public getDefaultClauseTypeForSectionId(sectionId: UUID): Promise<ClauseType> {
    return this.getClauseTypesForSectionId(sectionId).then((clauseConfigurations: ClauseTypeConfiguration[]) => {
      return clauseConfigurations.find((config) => config.isDefault).clauseType;
    });
  }

  public getSectionTypesForDocumentId(documentId: UUID): Promise<SectionTypeConfiguration[]> {
    let cached: Promise<SectionTypeConfiguration[]> = this._cachedSectionTypes[documentId.value];

    if (!cached) {
      cached = this._http
        .get<any[]>(`${this._baseUrl}/section-types/${documentId.value}`)
        .toPromise()
        .then((result: any[]) => {
          return result.map((value: any) => {
            return {
              sectionType: SectionType[value.sectionType as string],
              sectionGroupType: SectionGroupType[value.sectionGroupType as string],
              displayName: value.displayName,
            } as SectionTypeConfiguration;
          });
        })
        .catch((error: any) => {
          this._handleError(error, 'Failed to fetch section type configuration.', 'configuration-error');
          return Promise.reject(error);
        });

      this._cachedSectionTypes[documentId.value] = cached;
    }

    return cached;
  }

  public getClauseStylingForSectionIdAndClauseType(
    sectionId: UUID,
    clauseType: ClauseType
  ): Promise<ClauseStylingConfiguration> {
    let cached: Promise<ClauseStylingConfiguration[]> = this._cachedClauseStyling[sectionId.value];

    if (!cached) {
      cached = this._http
        .get<any[]>(`${this._baseUrl}/clause-styling/${sectionId.value}`)
        .toPromise()
        .then((result: any[]) => {
          return result.map((value: any) => {
            return {
              clauseType: ClauseType[value.clauseType as string],
              styling: value.styling ? value.styling.map((style) => ClauseStyle[style as string]) : [],
              fontSizePx: value.fontSizePx,
              paddingTopPx: value.paddingTopPx,
            } as ClauseStylingConfiguration;
          });
        })
        .catch((error: any) => {
          this._handleError(error, 'Failed to fetch clause styling configuration.', 'configuration-error');
          return Promise.reject(error);
        });

      this._cachedClauseStyling[sectionId.value] = cached;
    }

    return cached.then((styling) => styling.find((c) => c.clauseType === clauseType));
  }

  public getDocumentInformationConfigurationForSuite(suite: Suite): Promise<DocumentInformationField[]> {
    let cached: Promise<DocumentInformationField[]> = this._cachedDocumentInformationFields[suite];

    if (!cached) {
      const params: {[param: string]: string} = {
        suite: suite,
      };
      cached = this._http
        .get<any[]>(`${this._baseUrl}/document-information-for-suite`, {params})
        .toPromise()
        .then((result: any[]) => {
          return result.map((value: any) => {
            return {
              fieldType: FieldType[value.fieldType as string],
              carsAction: CarsAction[value.carsAction as string],
              documentInformationType: DocumentInformationType[value.documentInformationType as string],
              enumName: value.enumName,
              titleText: value.titleText,
              hintText: value.hintText,
              pattern: value.pattern,
              patternErrorText: value.patternErrorText,
              valueDisplayName: DocInfoValueDisplayNameType[value.valueDisplayName as string],
            } as DocumentInformationField;
          });
        })
        .catch((error: any) => {
          this._handleError(error, 'Failed to fetch document information configuration.', 'configuration-error');
          return Promise.reject(error);
        });

      this._cachedDocumentInformationFields[suite] = cached;
    }

    return cached;
  }

  public getDocumentMetricsConfigurationForSuite(suite: Suite): Promise<DocumentMetricsConfigItem[]> {
    let cached: Promise<DocumentMetricsConfigItem[]> = this._cachedDocumentMetricsConfigItems[suite];

    if (!cached) {
      const params: {[param: string]: string} = {
        suite: suite,
      };
      cached = this._http
        .get<any[]>(`${this._baseUrl}/document-metrics-items-for-suite`, {params})
        .toPromise()
        .then((result: any[]) => {
          return result.map((value: any) => {
            return {
              key: DocumentMetricsConfigItemType[value.key as string],
              singularDescription: value.singularDescription,
              pluralDescription: value.pluralDescription,
              guidanceText: value.guidanceText,
              iconName: value.iconName,
              isIconSvg: value.isIconSvg,
            } as DocumentMetricsConfigItem;
          });
        })
        .catch((error: any) => {
          this._handleError(error, 'Failed to fetch document metrics configuration.', 'configuration-error');
          return Promise.reject(error);
        });

      this._cachedDocumentMetricsConfigItems[suite] = cached;
    }

    return cached;
  }

  public getPermissions(): Promise<Map<CarsAction, PermissionASTNode>> {
    return this._cachedPermissions.size > 0
      ? Promise.resolve(this._cachedPermissions)
      : this._http
          .get<any[]>(`${this._baseUrl}/permissions/`)
          .toPromise()
          .then((result: any[]) => {
            result.forEach((value: any) => {
              const action: CarsAction = CarsAction[value.action as string];
              if (action === undefined) {
                throw new Error(`Unknown CarsAction ${value.action}.`);
              }
              const permissionASTNode: PermissionASTNode = deserialisePermissionASTNode(value.condition);
              this._cachedPermissions.set(action, permissionASTNode);
            });
            return this._cachedPermissions;
          })
          .catch((error: any) => {
            this._handleError(error, 'Failed to fetch permissions configuration.', 'configuration-error');
            return Promise.reject(error);
          });
  }

  public getToolbarItemsForSuite(suite: Suite): Promise<ToolbarItem[]> {
    let cached: Promise<ToolbarItem[]> = this._cachedToolbarItems[suite];

    if (!cached) {
      const params: {[param: string]: string} = {
        suite: suite,
      };
      cached = this._http
        .get<any[]>(`${this._baseUrl}/toolbar-items-for-suite`, {params})
        .toPromise()
        .then((result: any[]) => {
          return result.map((value: any) => {
            return {
              toolbarItemType: ToolbarItemType[value.toolbarItemType as string],
              tooltipText: value.tooltipText,
              ariaLabel: value.ariaLabel,
              itemKey: value.itemKey,
              enabled: EnabledToolbarFunctions.functionNameToImplementation[value.enabledFunctionName],
              active: value.activeFunctionName
                ? ActiveToolbarFunctions.functionNameToImplementation[value.activeFunctionName]
                : null,
              icons: value.icons,
              richTextType: RichTextType[value.richTextType as string],
              richTextArguments: value.richTextArguments,
            } as ToolbarItem;
          });
        })
        .catch((error: any) => {
          this._handleError(error, 'Failed to fetch toolbar items configuration.', 'configuration-error');
          return Promise.reject(error);
        });

      this._cachedToolbarItems[suite] = cached;
    }

    return cached;
  }

  public getSidebarItemsForSuite(suite: Suite): Promise<SidebarItem[]> {
    let cached: Promise<SidebarItem[]> = this._cachedSidebarItems[suite];

    if (!cached) {
      const params: {[param: string]: string} = {
        suite: suite,
      };
      cached = this._http
        .get<any[]>(`${this._baseUrl}/sidebar-items-for-suite`, {params})
        .toPromise()
        .then((result: any[]) => {
          return result.map((value: any) => {
            return {
              sidebarItemType: SidebarStatus[value.sidebarItemType as string],
              icon: value.icon,
              isIconSvg: value.isIconSvg,
              displayCarsAction: CarsAction[value.displayCarsAction as string],
            } as SidebarItem;
          });
        })
        .catch((error: any) => {
          this._handleError(error, 'Failed to fetch sidebar items configuration.', 'configuration-error');
          return Promise.reject(error);
        });

      this._cachedSidebarItems[suite] = cached;
    }

    return cached;
  }
}
