import {Logger} from 'app/error-handling/services/logger/logger.service';
import {Dictionary} from 'app/utils/typedefs';
import {Serialisable} from '../../utils/serialisable';
import {UUID} from '../../utils/uuid';
import {TreeArray} from '../tree/tree-array';
import {CaptionedFragment, Fragment, FragmentType, TextFragment} from '../types';

/**
 * An enumeration of the possible directions to split cells.
 */
export enum SplitDirection {
  HORIZONTALLY, // Split the cells horizontally
  VERTICALLY, // Split the cells vertically
}

/**
 * An enumeration of the possible justifications.
 */
export enum TextAlignment {
  TOP_LEFT,
  TOP_CENTER,
  TOP_RIGHT,
  MIDDLE_LEFT,
  MIDDLE_CENTER,
  MIDDLE_RIGHT,
  BOTTOM_LEFT,
  BOTTOM_CENTER,
  BOTTOM_RIGHT,
}

/**
 * An enumeration of all preview types
 */
export enum PreviewType {
  NONE,
  ROW_ABOVE,
  ROW_BELOW,
  COL_BEFORE,
  COL_AFTER,
  DELETE_ROW,
  DELETE_COL,
  SPLIT_VERTICALLY,
  SPLIT_HORIZONTALLY,
}

/**
 * A fragment representing a table cell, containing other fragments.
 *
 * @field rowSpan     {number}             This cell's rowspan
 * @field colSpan     {number}             This cell's colspan
 * @field deleted     {boolean}            True if this cell has been deleted
 * @field bold        {boolean}            True if this cell is bold
 * @field alignment   {TextAlignment}      This cell's text alignment
 */
export class TableCellFragment extends Fragment implements Serialisable {
  public height: number;
  public _rowSpan: number;
  public colSpan: number;
  public deleted: boolean;
  public bold: boolean;
  public alignment: TextAlignment;
  public headerRowBorder: boolean;
  public containsList: boolean;

  /**
   * @inheritDoc
   *
   * Getter for parent reference.
   */
  public get parent(): TableRowFragment {
    return super.parent as TableRowFragment;
  }

  /**
   * @inheritDoc
   *
   * Setter for parent reference which also keeps parentId up-to-date.
   */
  public set parent(fragment: TableRowFragment) {
    super.parent = fragment;
  }

  get rowSpan(): number {
    return this._rowSpan;
  }

  set rowSpan(rowSpan: number) {
    this._rowSpan = rowSpan;
    this.height = 16 * rowSpan;
  }

  /**
   * Create an empty table cell of a given row and column span.
   *
   * @param rowSpan   {number?}             The rowspan, default 1
   * @param colSpan   {number?}             The columnspan, default 1
   * @param deleted   {boolean?}            True if deleted, default false
   * @param bold      {boolean?}            True if cell is bold
   * @param alignment {TextAlignment}       How the cell contents should be aligned
   * @returns       {TableCellFragment}     The new table cell
   */
  public static empty(
    rowSpan: number = 1,
    colSpan: number = 1,
    deleted: boolean = false,
    bold: boolean = false,
    alignment: TextAlignment = TextAlignment.MIDDLE_LEFT
  ): TableCellFragment {
    const children: Fragment[] = [new TextFragment(null, '')];
    return new TableCellFragment(null, children, rowSpan, colSpan, deleted, bold, alignment);
  }

  constructor(
    id: UUID,
    children: Fragment[],
    rowSpan: number = 1,
    colSpan: number = 1,
    deleted: boolean = false,
    bold: boolean = false,
    alignment: TextAlignment = TextAlignment.MIDDLE_LEFT,
    headerRowBorder: boolean = false
  ) {
    super(id, FragmentType.TABLE_CELL, children, null);

    this.rowSpan = rowSpan;
    this.colSpan = colSpan;
    this.deleted = deleted;
    this.bold = bold;
    this.alignment = alignment;
    this.headerRowBorder = headerRowBorder;
  }

  /**
   * @returns {TableCellFragment} The next cell in the table or undefined if this is the last cell of the table. If the next cell is a
   * merged cell then this method returns the cell at the top left of the merged group.
   */
  public getNextCell(): TableCellFragment {
    const row = this.parent,
      table = row.parent;

    let nextX = row.children.indexOf(this) + this.colSpan,
      nextY = table.children.indexOf(row);

    if (nextX >= table.numColumns()) {
      nextX = 0;
      nextY += this.rowSpan;
    }

    let nextCell = table.at(nextY, nextX);

    while (nextCell && nextCell.deleted) {
      nextCell = table.at(--nextY, nextX);
    }

    return nextCell;
  }

  /**
   * Updates containsList to true iff the cell contains a list
   */
  public updateContainsList(): void {
    this.containsList = this.children.findIndex((f: Fragment) => f.is(FragmentType.LIST)) > -1;
  }

  /**
   * @returns {TableCellFragment} The previous cell in the table or undefined if this is the first cell of the table. If the previous
   * cell is a merged cell then this method returns the cell at the top left of the merged group.
   */
  public getPreviousCell(): TableCellFragment {
    const row = this.parent,
      table = row.parent;

    let prevX = row.children.indexOf(this) - 1,
      prevY = table.children.indexOf(row);

    if (prevX < 0) {
      prevX = table.numColumns() - 1;
      prevY -= 1;
    }

    let prevCell = table.at(prevY, prevX);

    if (prevCell) {
      prevX = prevX + 1 - prevCell.colSpan;

      while (prevCell.deleted) {
        prevCell = table.at(prevY--, prevX);
      }
    }

    return prevCell;
  }

  /**
   * @returns {TableCellFragment} The cell above in the table or undefined if there is no cell above. If the cell above is a merged cell
   * then this method returns the cell at the top left of the merged group.
   */
  public getCellAbove(): TableCellFragment {
    const row = this.parent,
      table = row.parent;

    let aboveX = row.children.indexOf(this),
      aboveY = table.children.indexOf(row) - 1;

    if (aboveY < 0) {
      return undefined;
    }

    let aboveCell = table.at(aboveY, aboveX);

    if (aboveCell) {
      aboveY = aboveY + 1 - aboveCell.rowSpan;

      while (aboveCell.deleted) {
        aboveCell = table.at(aboveY, aboveX--);
      }
    }

    return aboveCell;
  }

  /**
   * @returns {TableCellFragment} The cell below in the table or undefined if there is no cell below. If the cell below is a merged cell
   * then this method returns the cell at the top left of the merged group.
   */
  public getCellBelow(): TableCellFragment {
    const row = this.parent,
      table = row.parent;

    let belowX = row.children.indexOf(this);
    const belowY = table.children.indexOf(row) + this.rowSpan;

    if (belowY > table.numRows()) {
      return undefined;
    }

    let belowCell = table.at(belowY, belowX);

    while (belowCell && belowCell.deleted) {
      belowCell = table.at(belowY, --belowX);
    }

    return belowCell;
  }

  /**
   * Find and return the root (top left) cell for the merged block containing this cell.
   * if cell is not deleted or the root is not found then the given cell is returned.
   * @returns   {TableCellFragment}   The top left cell fragment
   */
  public getRootTableCellForCellInMergedBlock(): TableCellFragment {
    let cell: TableCellFragment = this;
    if (this.deleted) {
      const column = cell.index();
      const row = cell.parent.index();

      for (let searchColumn = column; searchColumn > column - this.colSpan && searchColumn >= 0; searchColumn--) {
        for (let searchRow = row; searchRow > row - this.rowSpan && searchRow >= 0; searchRow--) {
          cell = (cell.parent.parent as TableFragment).at(searchRow, searchColumn);
          if (!cell.deleted && cell.rowSpan === this.rowSpan && cell.colSpan === this.colSpan) {
            return cell;
          }
        }
      }
    }
    return cell;
  }

  /**
   * @inheritdoc
   *
   * A table cell cannot be split; use TableFragment::splitCell() instead.
   */
  public split(offset: number): TableCellFragment {
    return this;
  }

  public serialise(): any {
    const json: any = super.serialise();
    json.rowSpan = json._rowSpan;
    delete json._rowSpan;

    return json;
  }
}

/**
 * A fragment representing a row of a table, containing TableCellFragments.
 */
export class TableRowFragment extends Fragment {
  // Specialise type since a table row can only contain table cells
  public children: TreeArray<TableCellFragment>;

  /**
   * Static factory to create table rows with a given number of 1x1 columns.
   *
   * @param size {number}             The number of columns
   * @returns    {TableRowFragment}   The new row
   */
  public static withSize(size: number): TableRowFragment {
    const row: TableRowFragment = new TableRowFragment(null, []);
    for (let i: number = 0; i < size; ++i) {
      row.children.push(TableCellFragment.empty(1, 1));
    }

    return row;
  }

  constructor(id: UUID, children: TableCellFragment[]) {
    super(id, FragmentType.TABLE_ROW, children, null);
  }

  /**
   * @inheritDoc
   *
   * Getter for parent reference.
   */
  public get parent(): TableFragment {
    return super.parent as TableFragment; // Specialise type since a table row can only be the child of a table
  }

  /**
   * @inheritDoc
   *
   * Setter for parent reference which also keeps parentId up-to-date.
   */
  public set parent(fragment: TableFragment) {
    super.parent = fragment;
  }

  /**
   * @inheritdoc
   *
   * A table row cannot be split; use TableFragment::splitCell() instead.
   */
  public split(offset: number): TableRowFragment {
    return this;
  }
}

/**
 * Structure used to describe changes to fragments as part of a bulk operation
 */
export class FragmentUpdates {
  /**
   * The fragments which where updated
   */
  public fragmentsUpdated: Fragment[] = [];

  /**
   * The fragments which where deleted
   */
  public fragmentsDeleted: Fragment[] = [];

  /**
   * The fragments which where created
   */
  public fragmentsCreated: Fragment[] = [];

  constructor(create: Fragment[] = [], update: Fragment[] = [], deletes: Fragment[] = []) {
    this.fragmentsCreated = create;
    this.fragmentsUpdated = update;
    this.fragmentsDeleted = deletes;
  }
}

/**
 * A fragment representing a table with a caption.
 *
 * @field caption {string|TextFragment}   The table caption
 */
export class TableFragment extends CaptionedFragment {
  // Specialise type since a table row can only contain table cells
  public children: TreeArray<TableRowFragment>;
  public landscape: boolean;

  // Percentage widths of each column
  public columnWidths: number[];

  public hasHeaderRow: boolean;

  /**
   * Static factory to create rectangular tables with a given size.
   *
   * @param rows {number}          The number of rows
   * @param cols {number}          The number of columns
   * @returns    {TableFragment}   The created table fragment
   */
  public static withSize(rows: number, cols: number): TableFragment {
    const table: TableFragment = new TableFragment(null);

    for (let i: number = 0; i < rows; ++i) {
      table.children.push(TableRowFragment.withSize(cols));
    }

    table.columnWidths = [];
    table.columnWidths.fill(1 / cols, 0, cols);

    return table;
  }

  /**
   * Tests to see if two rectangles intersect one another
   * @param r1Left The first rectangles left position
   * @param r1Top  The first rectangles top position
   * @param r1Right  The first rectangles right position
   * @param r1Bottom  The first rectangles bottom position
   * @param r2Left  The second rectangles left position
   * @param r2Top The second rectangles top position
   * @param r2Right The second rectangles right position
   * @param r2Bottom The second rectangles bottom position
   */
  public static intersect(r1Left, r1Top, r1Right, r1Bottom, r2Left, r2Top, r2Right, r2Bottom): boolean {
    return !(r2Left > r1Right || r2Right < r1Left || r2Top > r1Bottom || r2Bottom < r1Top);
  }

  constructor(
    id: UUID,
    children: TableRowFragment[] = [],
    caption: string = 'New Table',
    landscape: boolean = false,
    columnWidths: number[] = [],
    hasHeaderRow: boolean = false
  ) {
    super(id, FragmentType.TABLE, children, null, caption);
    this.landscape = landscape;
    this.columnWidths = columnWidths;
    this.hasHeaderRow = hasHeaderRow;
  }

  /**
   * @inheritdoc
   */
  public split(offset: number): TableFragment {
    throw new Error('A TableFragment cannot be split');
  }

  /**
   * Convenience function for extracting a given row and column from a table.  Returns
   * undefined if either row or col are out of bounds.
   *
   * @param row {number}              The row
   * @param col {number}              The column
   * @returns   {TableCellFragment}   The cell fragment
   */
  public at(row: number, col: number): TableCellFragment {
    return this.children[row] ? this.children[row].children[col] : undefined;
  }

  /**
   * @returns the number of rows in this table.
   */
  public numRows(): number {
    return this.children.length;
  }

  /**
   * @returns the number of cells in the first row of this table, or 0 if the
   * table contains no rows.
   */
  public numColumns(): number {
    return this.children.length > 0 ? this.children[0].children.length : 0;
  }

  /**
   * Insert a row into the table above or below the given row, defaulting to the end of the table.
   *
   * @param clonedRowIndex {number}   The insertion point
   * @param above          {boolean}  Insert above, default to false i.e inserting below
   */
  public insertRow(clonedRowIndex: number = this.children.length - 1, above: boolean = false): FragmentUpdates {
    const fragmentsToCreate: Dictionary<Fragment> = {};
    const fragmentsToUpdate: Dictionary<Fragment> = {};
    const newRow: TableRowFragment = new TableRowFragment(null, []);
    for (let colIndex: number = 0; colIndex < this.children[clonedRowIndex].children.length /* no increment */; ) {
      const cell: TableCellFragment = this.at(clonedRowIndex, colIndex);
      const next: TableCellFragment = this.at(above ? clonedRowIndex - 1 : clonedRowIndex + 1, colIndex);

      // Find the cell above us which is the root of this row/col span
      let rootRow: number = clonedRowIndex;
      let root: TableCellFragment;
      while ((root = this.at(rootRow, colIndex)) && root.deleted) {
        --rootRow;
      }

      // If the cell next to us is deleted and has the same rowSpan, we're in the middle of a rowSpan
      // that the cell should be inserted into
      if (
        cell &&
        next &&
        ((!above && next.deleted) || (above && cell.deleted)) &&
        cell.rowSpan === next.rowSpan &&
        cell.rowSpan > 1
      ) {
        const newRowSpan: number = cell.rowSpan + 1;
        for (let j: number = colIndex; j < colIndex + root.colSpan; ++j) {
          newRow.children.push(TableCellFragment.empty(newRowSpan, cell.colSpan, true));
          for (let i: number = rootRow; i < rootRow + newRowSpan - 1; ++i) {
            const cellAt = this.at(i, j);
            cellAt.rowSpan = newRowSpan;
            fragmentsToUpdate[cellAt.id.value] = cellAt;
          }
        }
      } else {
        for (let j: number = colIndex; j < colIndex + root.colSpan; ++j) {
          const deleted: boolean = root.colSpan > 1 && j > colIndex;
          newRow.children.push(TableCellFragment.empty(1, cell.colSpan, deleted));
        }
      }

      // Advance by as many columns as were inserted
      colIndex += root.colSpan;
    }

    this.children.splice(above ? clonedRowIndex : clonedRowIndex + 1, 0, newRow);

    // Append the new fragments to create
    fragmentsToCreate[newRow.id.value] = newRow;

    return new FragmentUpdates(Object.values(fragmentsToCreate), Object.values(fragmentsToUpdate), []);
  }

  /**
   * Insert a new column before or after the given column index, defaulting to the end of the table.
   *
   * @param afterColum {number}   The insertion point
   * @param before     {boolean}  Insert before, default to false i.e inserting after
   */
  public insertColumn(
    clonedColIndex: number = this.children[0].children.length - 1,
    before: boolean = false
  ): FragmentUpdates {
    const fragmentsToCreate: Dictionary<Fragment> = {};
    const fragmentsToUpdate: Dictionary<Fragment> = {};
    const fragmentsToDelete: Dictionary<Fragment> = {};

    for (let rowIndex: number = 0; rowIndex < this.children.length /* no increment */; ) {
      const cell: TableCellFragment = this.at(rowIndex, clonedColIndex);
      const next: TableCellFragment = this.at(rowIndex, before ? clonedColIndex - 1 : clonedColIndex + 1);

      // Find the cell above us which is the root of this row/col span
      let rootCol: number = clonedColIndex;
      let root: TableCellFragment;
      while ((root = this.at(rowIndex, rootCol)) && root.deleted) {
        --rootCol;
      }

      // If the cell next to us is deleted and has the same colSpan, we're in the middle of a
      // colSpan that the cell should be inserted into
      if (
        cell &&
        next &&
        ((!before && next.deleted) || (before && cell.deleted)) &&
        cell.colSpan === next.colSpan &&
        cell.colSpan > 1
      ) {
        const oldColSpan: number = cell.colSpan;
        for (let i: number = rowIndex; i < rowIndex + root.rowSpan; ++i) {
          const newCellFragment: TableCellFragment = TableCellFragment.empty(cell.rowSpan, oldColSpan + 1, true);
          const deletedFragments: TableCellFragment[] = this.children[i].children.splice(
            before ? clonedColIndex : clonedColIndex + 1,
            0,
            newCellFragment
          );

          for (let j: number = rootCol; j < rootCol + oldColSpan + 1; ++j) {
            const cellAt = this.at(i, j);
            cellAt.colSpan = oldColSpan + 1;
            fragmentsToUpdate[cellAt.id.value] = cellAt;
          }

          deletedFragments.filter((f) => f != null).forEach((f) => (fragmentsToDelete[f.id.value] = f));
          fragmentsToCreate[newCellFragment.id.value] = newCellFragment;
        }
      } else {
        for (let i: number = rowIndex; i < rowIndex + root.rowSpan; ++i) {
          const deleted: boolean = root.rowSpan > 1 && i > rowIndex;
          const newCellFragment: TableCellFragment = TableCellFragment.empty(cell.rowSpan, 1, deleted);
          fragmentsToCreate[newCellFragment.id.value] = newCellFragment;

          this.children[i].children.splice(before ? clonedColIndex : clonedColIndex + 1, 0, newCellFragment);
        }
      }

      // Advance by as many rows as were inserted
      rowIndex += root.rowSpan;
    }

    return new FragmentUpdates(
      Object.values(fragmentsToCreate),
      Object.values(fragmentsToUpdate),
      Object.values(fragmentsToDelete)
    );
  }

  /**
   * Delete the row at a given index.
   *
   * @param row {number}         The row index to delete
   * @returns   {TextFragment[]} Array of fragments to create in the backend
   */
  public deleteRow(deleteRow: number): FragmentUpdates {
    const deleteFragments: Dictionary<Fragment> = {};
    const updateFragments: Dictionary<Fragment> = {};

    const forEachReverse = (array: Array<any>, callback: Function) => {
      for (let i = array.length - 1; i >= 0; i--) {
        callback(array[i], i, array);
      }
    };

    forEachReverse(this.children, (row: TableRowFragment, rowNum: number) => {
      forEachReverse(row.children, (cell: TableCellFragment, colNum: number) => {
        if (cell.deleted) {
          return;
        }

        // Test if this cell spans the column to delete
        // if (colNum <= column && colNum + cell.colSpan > column) {
        if (rowNum <= deleteRow && rowNum + cell.rowSpan > deleteRow) {
          const {rowSpan, colSpan} = cell;
          for (let i = rowNum + rowSpan - 1; i >= rowNum; i--) {
            for (let j = colNum + colSpan - 1; j >= colNum; j--) {
              const cellAt = this.at(i, j);

              if (i === deleteRow) {
                deleteFragments[cellAt.id.value] = cellAt;

                if (cellAt === cell && rowSpan > 1) {
                  // We are deleting the active cell, make the next cell active
                  const newActiveParent = this.at(i + 1, j);
                  newActiveParent.deleted = false;
                  updateFragments[newActiveParent.id.value] = newActiveParent;

                  // Move the child elements into the new parent
                  cell.children.forEach((childCell) => {
                    newActiveParent.children.push(childCell);
                    updateFragments[childCell.id.value] = childCell;
                  });
                } else {
                  cellAt.rowSpan = rowSpan - 1;
                  cellAt.deleted = true;
                  updateFragments[cellAt.id.value] = cellAt;
                }
              } else {
                cellAt.rowSpan = rowSpan - 1;
                updateFragments[cellAt.id.value] = cellAt;
              }
            }
          }
        }
      });
    });

    const rowFragmentDeleted = this.children[deleteRow];
    deleteFragments[rowFragmentDeleted.id.value] = rowFragmentDeleted;

    return new FragmentUpdates([], Object.values(updateFragments), Object.values(deleteFragments));
  }

  /**
   * Delete the column at a given index.
   *
   * @param column {number}         The column index to delete
   * @returns      {TextFragment[]} Array of fragments to create in the backend
   */
  public deleteColumn(column: number): FragmentUpdates {
    const deleteFragments: Dictionary<Fragment> = {};
    const updateFragments: Dictionary<Fragment> = {};

    this.children.forEach((row: TableRowFragment, rowNum: number) => {
      row.children.forEach((cell: TableCellFragment, colNum: number) => {
        if (cell.deleted) {
          return;
        }

        // Test if this cell spans the column to delete
        if (colNum <= column && colNum + cell.colSpan > column) {
          const {rowSpan, colSpan} = cell;
          for (let i = rowNum; i < rowNum + rowSpan; i++) {
            for (let j = colNum + colSpan - 1; j >= colNum; j--) {
              const cellAt = this.at(i, j);

              if (j === column) {
                deleteFragments[cellAt.id.value] = cellAt;

                if (cellAt === cell && colSpan > 1) {
                  // We are deleting the active cell, make the next cell active
                  const newActiveParent = this.at(i, j + 1);
                  newActiveParent.deleted = false;
                  updateFragments[newActiveParent.id.value] = newActiveParent;

                  // Move the child elements into the new parent
                  cell.children.forEach((childCell) => {
                    newActiveParent.children.push(childCell);
                    updateFragments[childCell.id.value] = childCell;
                  });
                }
              } else {
                cellAt.colSpan = colSpan - 1;
                updateFragments[cellAt.id.value] = cellAt;
              }
            }
          }
        }
      });

      deleteFragments[this.at(rowNum, column).id.value] = this.at(rowNum, column);
    });

    return new FragmentUpdates([], Object.values(updateFragments), Object.values(deleteFragments));
  }

  /**
   * Print this table for debug purposes.
   *
   * @param header {string?}   An optional header
   * @returns      {string}    The output string
   */
  public toString(header?: string): string {
    const lines: string[] = header ? [header] : [];
    for (let i: number = 0; i < this.children.length; ++i) {
      const line: string = this.children[i].children
        .map((cell: TableCellFragment) => {
          let str: string = `(${cell.rowSpan},${cell.colSpan}),`;
          str += cell.deleted ? '----' : 'cell';
          return str;
        })
        .join('  ');

      lines.push(line);
    }

    return lines.join('\n');
  }

  /**
   * Split the cell (row, column) if it has rowSpan or colSpan > 1.
   *
   * @param row            {number}          The row index
   * @param col            {number}          The column index
   * @param direction      {SplitDirection}  The direction to split the cell
   * @returns              {TextFragment[]}  Array of new fragments to be created in the backend
   */
  public splitCell(row: number, col: number, direction: SplitDirection): FragmentUpdates {
    const updateFragments: Dictionary<Fragment> = {};
    const createFragments: Dictionary<Fragment> = {};
    const targetCell: TableCellFragment = this.at(row, col);
    const rowSpan: number = targetCell.rowSpan;
    const colSpan: number = targetCell.colSpan;

    switch (direction) {
      case SplitDirection.VERTICALLY:
        {
          if (targetCell.colSpan > 1) {
            for (let i: number = 0; i < rowSpan; ++i) {
              let cell = this.at(i + row, col);
              cell.colSpan = 1;
              updateFragments[cell.id.value] = cell;

              for (let j: number = 1; j < colSpan; ++j) {
                cell = this.at(i + row, j + col);
                cell.colSpan -= 1;
                cell.deleted = i > 0 || j > 1;
                updateFragments[cell.id.value] = cell;

                if (!cell.deleted && !cell.children.length) {
                  const newTextFragment: TextFragment = TextFragment.empty();
                  cell.children.push(newTextFragment);
                  createFragments[newTextFragment.id.value] = newTextFragment;
                }
              }
            }
          }
        }
        break;

      case SplitDirection.HORIZONTALLY:
        {
          if (targetCell.rowSpan > 1) {
            for (let j: number = 0; j < colSpan; ++j) {
              let cell = this.at(row, j + col);
              cell.rowSpan = 1;
              updateFragments[cell.id.value] = cell;

              for (let i: number = 1; i < rowSpan; ++i) {
                cell = this.at(i + row, j + col);
                cell.rowSpan -= 1;
                cell.deleted = i > 1 || j > 0;
                updateFragments[cell.id.value] = cell;

                if (!cell.deleted && !cell.children.length) {
                  const newTextFragment: TextFragment = TextFragment.empty();
                  cell.children.push(newTextFragment);
                  createFragments[newTextFragment.id.value] = newTextFragment;
                }
              }
            }
          }
        }
        break;
    }
    this.updateHeaderCells();
    return new FragmentUpdates(Object.values(createFragments), Object.values(updateFragments), []);
  }

  /**
   * Merge the cells defined by the region closed by top, left, bottom and right.  Throws
   * an Error if the cells do not define a mergeable region.
   *
   * @param top    {number}       The start row index
   * @param left   {number}       The start column index
   * @param bottom {number}       The end row index
   * @param right  {number}       The end column index
   * @throws       {Error}        If the region cannot be merged
   * @returns      {Fragment[]}   Array of fragments to be updated in the backend (will have new parentIds)
   */
  public mergeCells(top: number, left: number, bottom: number, right: number): FragmentUpdates {
    const updateFragments: Dictionary<Fragment> = {};

    this.validateTableShape();

    // Check that the cells being merged don't overlap the header row (if it exists)
    if (!this.overflowsHeaderRow(top, left, bottom, right)) {
      // A region is valid iff it doesn't straddle any row/col spans, iff the region includes all
      // cells given by flood-filling from top-left to bottom-right
      if (this.isValidSelection(top, left, bottom, right)) {
        const topLeft: TableCellFragment = this.at(top, left);
        const childrenToMove: Fragment[] = [];

        for (let i: number = top; i <= bottom; ++i) {
          for (let j: number = left; j <= right; ++j) {
            // Empty fragments to leave as children in the cell.
            const childrenToKeep: Fragment[] = [];
            const cell: TableCellFragment = this.at(i, j);
            cell.rowSpan = bottom - top + 1;
            cell.colSpan = right - left + 1;
            cell.deleted = true;

            // Leave empty fragments in the cell. Move others onto topLeft cell.
            cell.children.splice(0).forEach((child: Fragment) => {
              child.length() > 0 ? childrenToMove.push(child) : childrenToKeep.push(child);
            });
            cell.children.push(...childrenToKeep);
            updateFragments[cell.id.value] = cell;
          }
        }

        childrenToMove.forEach((cell) => {
          updateFragments[cell.id.value] = cell;
        });

        topLeft.deleted = false;
        updateFragments[topLeft.id.value] = topLeft;

        if (childrenToMove.length > 0) {
          topLeft.children.push(...childrenToMove);
        }

        this.updateHeaderCells();
      } else {
        throw new Error('Cannot merge a non-rectangular cell range');
      }
    } else {
      throw new Error('Cannot merge across the header row');
    }

    this.validateTableShape();

    return new FragmentUpdates([], Object.values(updateFragments), []);
  }

  /**
   * Toggle bold for the cells defined by the region closed by top, left, bottom and right.
   *
   * @param top    {number} The start row index
   * @param left   {number} The start column index
   * @param bottom {number} The end row index
   * @param right  {number} The end column index
   */
  public toggleBold(top: number, left: number, bottom: number, right: number): FragmentUpdates {
    const updateFragments: Dictionary<Fragment> = {};

    for (let i: number = top; i <= bottom; ++i) {
      for (let j: number = left; j <= right; ++j) {
        const cell = this.at(i, j);
        cell.bold = !cell.bold;
        updateFragments[cell.id.value] = cell;
      }
    }

    return new FragmentUpdates([], Object.values(updateFragments), []);
  }

  /**
   * Toggle the orientation
   */
  public toggleLandscape(): void {
    this.landscape = !this.landscape;
  }

  /**
   * Toggle whether the table has a header row.
   */
  public toggleHasHeaderRow(): void {
    if (this.validHeaderRow()) {
      this.hasHeaderRow = !this.hasHeaderRow;
      this.updateHeaderCells();
    } else {
      throw new Error('Cannot create a non-rectangular header row');
    }
  }

  /**
   * Checks if a potential header row is rectangular.
   */
  public validHeaderRow(): boolean {
    const topLeft: TableCellFragment = this.at(0, 0);
    return this.isValidSelection(0, 0, topLeft.rowSpan - 1, this.numColumns() - 1);
  }

  /**
   * Has each cell check if it is part of the header row and updates its status.
   */
  public updateHeaderCells(): void {
    const headerRowHeight: number = this.at(0, 0).rowSpan;

    for (const row of this.children) {
      for (const cell of row.children) {
        cell.headerRowBorder = row.index() + cell.rowSpan === headerRowHeight && this.hasHeaderRow;
      }
    }
  }

  /**
   * Tests whether the given boundary overlaps the header row. Defaults to false if the heading
   * row doesn't exist.
   */
  public overflowsHeaderRow(top: number, left: number, bottom: number, right: number): boolean {
    if (!this.hasHeaderRow) {
      return false;
    }
    let overflowsHeaderRow: boolean = false;

    for (const row of this.children) {
      for (const cell of row.children) {
        if (cell.headerRowBorder) {
          if (
            cell.index() >= left &&
            cell.index() <= right &&
            row.index() + cell.rowSpan >= top &&
            row.index() + cell.rowSpan <= bottom
          ) {
            overflowsHeaderRow = true;
          }
        }
      }
    }

    return overflowsHeaderRow;
  }

  /**
   * Set alignment for the cells defined by the region closed by top, left, bottom and right.
   *
   * @param top         {number}        The start row index
   * @param left        {number}        The start column index
   * @param bottom      {number}        The end row index
   * @param right       {number}        The end column index
   * @param alignment   {TextAlignment} The content alignment
   */
  public setAlignment(
    top: number,
    left: number,
    bottom: number,
    right: number,
    alignment: TextAlignment
  ): FragmentUpdates {
    const updateFragments: Dictionary<Fragment> = {};

    for (let i: number = top; i <= bottom; ++i) {
      for (let j: number = left; j <= right; ++j) {
        const cell = this.at(i, j);
        cell.alignment = alignment;
        updateFragments[cell.id.value] = cell;
      }
    }

    return new FragmentUpdates([], Object.values(updateFragments), []);
  }

  /**
   * Tests for elements which extend outside of the selected bounds
   *
   * @param top    {number}    The start row index
   * @param left   {number}    The start column index
   * @param bottom {number}    The end row index
   * @param right  {number}    The end column index
   * @returns      {boolean}   True for valid selection
   */
  public isValidSelection(top: number, left: number, bottom: number, right: number): boolean {
    const invalidCells = this.detectOutOfBoundsCells(top, left, bottom, right);
    return !invalidCells || invalidCells.length === 0;
  }

  /**
   * Tests for elements which extend outside of the selected bounds
   *
   * @param top    {number}       The start row index
   * @param left   {number}       The start column index
   * @param bottom {number}       The end row index
   * @param right  {number}       The end column index
   * @returns      {TableCellFragment[]}   Array of fragments out of the selection bounds
   */
  public detectOutOfBoundsCells(top: number, left: number, bottom: number, right: number): TableCellFragment[] {
    return this.children.reduce(
      (invalid: TableCellFragment[], row: TableRowFragment, index: number): TableCellFragment[] => {
        const invalidCells = row.children.filter((cell: TableCellFragment, span: number) => {
          if (cell.deleted) {
            return false;
          }

          // Look for above, below, left and right
          let outOfBounds = false;
          const cellLeft = span;
          const cellTop = index;
          const cellRight = span + cell.colSpan - 1;
          const cellBottom = index + cell.rowSpan - 1;

          if (TableFragment.intersect(left, top, right, bottom, cellLeft, cellTop, cellRight, cellBottom)) {
            // Test if the intersection spans outside of the bounds
            if (cellTop < top || cellBottom > bottom || cellLeft < left || cellRight > right) {
              outOfBounds = true;
            }
          }

          return outOfBounds;
        });

        invalid.push(...invalidCells);
        return invalid;
      },
      []
    );
  }

  private validateTableShape(): void {
    this.children.forEach((row: TableRowFragment, rowNum: number) => {
      row.children.forEach((cell: TableCellFragment, colNum: number) => {
        if (cell.deleted) {
          return;
        }

        for (let i = rowNum; i < rowNum + cell.rowSpan; i++) {
          for (let j = colNum; j < colNum + cell.colSpan; j++) {
            const cellAt = this.at(i, j);
            if (cellAt === cell) {
              continue;
            }

            if (cellAt.rowSpan !== cell.rowSpan || cellAt.colSpan !== cell.colSpan || !cellAt.deleted) {
              Logger.error(
                'table-layout-error',
                `Cell at [${j},${i}] should be size [${cellAt.colSpan},${cellAt.rowSpan}]` +
                  `, but actually [${cell.colSpan},${cell.rowSpan}], is deleted ${cellAt.deleted}`
              );
            }
          }
        }
      });
    });
  }

  /**
   * Updates containsList on all cells in the table.
   */
  public updateIfCellsContainLists(): void {
    this.children.forEach((r: TableRowFragment) => {
      r.children.forEach((c: TableCellFragment) => {
        c.updateContainsList();
      });
    });
  }
}
