import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UUID} from 'app/utils/uuid';
import {environment} from 'environments/environment';
import {Observable} from 'rxjs';

export interface SectionCloneRequest {
  sourceDocumentId: string;
  targetDocumentId: string;
  sourceSectionIds: string[];
}

@Injectable({
  providedIn: 'root',
})
export class CloningService {
  constructor(private _http: HttpClient) {}

  public cloneSections(sourceDocumentId: UUID, targetDocumentId: UUID, sourceSectionIds: UUID[]): Observable<string> {
    const body: SectionCloneRequest = {
      sourceDocumentId: sourceDocumentId.value,
      targetDocumentId: targetDocumentId.value,
      sourceSectionIds: sourceSectionIds.map((id) => id.value),
    };
    return this._http.post(`${environment.apiHost}/sections/clone-sections`, body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      responseType: 'text',
    });
  }
}
