import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {CarsPermissionPipesModule} from '../../permissions/pipes/cars-permission-pipes.module';
import {AdministrationModule} from './administration/administration.module';
import {CoreDocumentLinkageModule} from './core-document-linkage/core-document-linkage.module';
import {DocumentInformationComponent} from './document-information.component';
import {EnumMultiSelectInputModule} from './enum-multi-select-input/enum-multi-select-input.module';
import {EnumSelectInputModule} from './enum-select-input/enum-select-input.module';
import {FreeTextInputModule} from './free-text-input/free-text-input.module';
import {JiraInformationModule} from './jira-information/jira-information.module';
import {LogoUploadModule} from './logo-upload/logo-upload.module';
import {DocumentMetricsModule} from './metrics/document-metrics/document-metrics.module';
import {NaaDocumentLinkageModule} from './naa-document-linkage/naa-document-linkage.module';
import {NonEditableFieldModule} from './non-editable-field/non-editable-field.module';

@NgModule({
  imports: [
    CommonModule,
    FreeTextInputModule,
    EnumSelectInputModule,
    EnumMultiSelectInputModule,
    ReactiveFormsModule,
    DocumentMetricsModule,
    JiraInformationModule,
    NonEditableFieldModule,
    CarsPermissionPipesModule,
    NaaDocumentLinkageModule,
    CoreDocumentLinkageModule,
    AdministrationModule,
    LogoUploadModule,
  ],
  declarations: [DocumentInformationComponent],
  exports: [DocumentInformationComponent],
})
export class DocumentInformationModule {}
