import {Logger} from 'app/error-handling/services/logger/logger.service';
import {Fragment, FragmentType} from './types';

enum ChildCount {
  NONE,
  ZERO_OR_ONE,
  AT_LEAST_ONE,
  ANY,
}

export enum EquationType {
  DISPLAY = 'DISPLAY',
  INLINE = 'INLINE',
}

interface ChildValidity {
  types: (FragmentType | EquationType)[];
  count: ChildCount;
}
export class TreeStructureValidator {
  private static readonly VALID_CHILDREN: Map<FragmentType, ChildValidity> = new Map<FragmentType, ChildValidity>([
    [FragmentType.TEXT, {types: [], count: ChildCount.NONE}],

    [FragmentType.SUPERSCRIPT, {types: [], count: ChildCount.NONE}],

    [FragmentType.SUBSCRIPT, {types: [], count: ChildCount.NONE}],

    [FragmentType.MEMO, {types: [], count: ChildCount.NONE}],

    [FragmentType.LIST, {types: [FragmentType.LIST_ITEM], count: ChildCount.AT_LEAST_ONE}],

    [
      FragmentType.LIST_ITEM,
      {
        types: [
          FragmentType.TEXT,
          FragmentType.SUBSCRIPT,
          FragmentType.SUPERSCRIPT,
          FragmentType.MEMO,
          EquationType.INLINE,
          FragmentType.INLINE_REFERENCE,
          FragmentType.ANCHOR,
        ],
        count: ChildCount.AT_LEAST_ONE,
      },
    ],

    [FragmentType.EQUATION, {types: [FragmentType.TABLE], count: ChildCount.ZERO_OR_ONE}],

    [FragmentType.FIGURE, {types: [], count: ChildCount.NONE}],

    [
      FragmentType.TABLE_CELL,
      {
        types: [
          FragmentType.TEXT,
          FragmentType.SUBSCRIPT,
          FragmentType.SUPERSCRIPT,
          FragmentType.MEMO,
          FragmentType.LIST,
          EquationType.INLINE,
          FragmentType.FIGURE,
          FragmentType.INLINE_REFERENCE,
          FragmentType.ANCHOR,
        ],
        count: ChildCount.AT_LEAST_ONE,
      },
    ],

    [FragmentType.TABLE_ROW, {types: [FragmentType.TABLE_CELL], count: ChildCount.AT_LEAST_ONE}],

    [FragmentType.TABLE, {types: [FragmentType.TABLE_ROW], count: ChildCount.AT_LEAST_ONE}],

    [
      FragmentType.CLAUSE,
      {
        types: [
          FragmentType.TEXT,
          FragmentType.SUBSCRIPT,
          FragmentType.SUPERSCRIPT,
          FragmentType.MEMO,
          FragmentType.LIST,
          EquationType.INLINE,
          EquationType.DISPLAY,
          FragmentType.FIGURE,
          FragmentType.TABLE,
          FragmentType.INLINE_REFERENCE,
          FragmentType.DOCUMENT_REFERENCE,
          FragmentType.INTERNAL_DOCUMENT_REFERENCE,
          FragmentType.INTERNAL_INLINE_REFERENCE,
          FragmentType.ANCHOR,
          FragmentType.DOCUMENT_INFORMATION,
          FragmentType.READONLY,
          FragmentType.INPUT,
          FragmentType.REFERENCE_INPUT,
          FragmentType.UNIT_INPUT,
        ],
        count: ChildCount.AT_LEAST_ONE,
      },
    ],

    [FragmentType.SECTION, {types: [FragmentType.CLAUSE, FragmentType.CLAUSE_GROUP], count: ChildCount.ANY}],

    [FragmentType.DOCUMENT, {types: [FragmentType.SECTION, FragmentType.SECTION_GROUP], count: ChildCount.ANY}],

    [FragmentType.ROOT, {types: [FragmentType.DOCUMENT], count: ChildCount.ANY}],

    [FragmentType.DOCUMENT_REFERENCE, {types: [], count: ChildCount.NONE}],

    [FragmentType.INLINE_REFERENCE, {types: [], count: ChildCount.NONE}],

    [FragmentType.INTERNAL_DOCUMENT_REFERENCE, {types: [], count: ChildCount.NONE}],

    [FragmentType.INTERNAL_INLINE_REFERENCE, {types: [], count: ChildCount.NONE}],

    [FragmentType.ANCHOR, {types: [], count: ChildCount.NONE}],

    [FragmentType.DUMMY, {types: [], count: ChildCount.NONE}],

    [FragmentType.DOCUMENT_INFORMATION, {types: [FragmentType.FIGURE], count: ChildCount.ZERO_OR_ONE}],

    [
      FragmentType.CLAUSE_GROUP,
      {types: [FragmentType.CLAUSE_GROUP, FragmentType.CLAUSE], count: ChildCount.AT_LEAST_ONE},
    ],

    [FragmentType.READONLY, {types: [], count: ChildCount.NONE}],

    [
      FragmentType.INPUT,
      {
        types: [FragmentType.TEXT, FragmentType.SUBSCRIPT, FragmentType.SUPERSCRIPT, FragmentType.MEMO],
        count: ChildCount.AT_LEAST_ONE,
      },
    ],

    [
      FragmentType.REFERENCE_INPUT,
      {
        types: [FragmentType.INTERNAL_INLINE_REFERENCE],
        count: ChildCount.ANY,
      },
    ],

    [FragmentType.UNIT_INPUT, {types: [], count: ChildCount.NONE}],

    [FragmentType.SECTION_GROUP, {types: [FragmentType.SECTION], count: ChildCount.AT_LEAST_ONE}],
  ]);

  public static areValidTrees(roots: Fragment[]): boolean {
    let badTrees: number = 0;
    roots = roots || [];

    roots.forEach((root: Fragment) => {
      if (!this.isValidTree(root)) {
        badTrees++;
      }
    });

    return badTrees === 0;
  }

  public static isValidTree(root: Fragment): boolean {
    if (!root) {
      return false;
    }
    let errors: number = 0;

    root.iterateDown(null, null, (fragment: Fragment) => {
      const parent: Fragment = fragment.parent;
      const isValid: boolean = parent ? this.isValidRelationship(parent, fragment) : true;
      const hasValidCount: boolean = this.hasValidChildCount(fragment);
      if (!isValid || !hasValidCount) {
        errors++;
      }
    });

    return errors === 0;
  }

  /**
   * Returns true iff the fragment is in a valid position in the tree with regard to its parent only.
   * To check an entire subtree, use isValidTree().
   *
   * @param fragment {Fragment} The fragment to check.
   */
  public static isValidInsertion(fragment: Fragment, logErrors: boolean = false): boolean {
    const hasValidChildCount: boolean = this.hasValidChildCount(fragment, logErrors);
    const parentHasValidChildCount: boolean = this.hasValidChildCount(fragment.parent, logErrors);
    const hasValidParentType: boolean = this.isValidRelationship(fragment.parent, fragment, logErrors);
    return hasValidChildCount && parentHasValidChildCount && hasValidParentType;
  }

  public static isValidRelationship(parent: Fragment, child: Fragment, logErrors: boolean = false): boolean {
    if (!parent) {
      return true;
    }

    return TreeStructureValidator.isValidRelationshipWithParentOfType(parent?.type, child, logErrors);
  }

  public static isValidRelationshipWithParentOfType(
    parentType: FragmentType,
    child: Fragment,
    logErrors: boolean = false
  ): boolean {
    if (!child) {
      return true;
    }

    const validity: ChildValidity = TreeStructureValidator.VALID_CHILDREN.get(parentType);
    let isValidType: boolean;
    if (child.is(FragmentType.EQUATION)) {
      isValidType = validity.types.includes(child.isInline() ? EquationType.INLINE : EquationType.DISPLAY);
    } else {
      isValidType = validity.types.includes(child.type);
    }

    if (!isValidType && logErrors) {
      Logger.error(
        'tree-structure-error',
        'invalid tree structure : ' + FragmentType[parentType] + ' had child of type ' + FragmentType[child.type]
      );
    }
    return isValidType;
  }

  /**
   * Returns an array of all the valid ancestor types for a fragment of the given fragment type.
   * For equations, an EquationType should be passed in instead, to specificy if we are refering
   * to an inline equation or a display equation.
   *
   * As an  example, the list for clauses include documents as well as sections, as documents
   * can include sections.
   */
  public static getValidAncestorTypes(
    fragmentType: Exclude<FragmentType, FragmentType.EQUATION> | EquationType
  ): FragmentType[] {
    const cache: Partial<Record<FragmentType | EquationType, boolean>> = {};
    return Array.from(TreeStructureValidator.VALID_CHILDREN.keys()).reduce(
      (returnArray: FragmentType[], parentType: FragmentType) => {
        if (TreeStructureValidator.isValidAncestorType(parentType, fragmentType, cache)) {
          returnArray.push(parentType);
        }
        return returnArray;
      },
      []
    );
  }

  private static isValidAncestorType(
    parentType: FragmentType | EquationType,
    childType: FragmentType | EquationType,
    cache: Partial<Record<FragmentType | EquationType, boolean>> = {},
    checking: (FragmentType | EquationType)[] = [] // prevent looping, for example with the CLAUSE_GROUP which can contain itself
  ): boolean {
    const cachedResult: boolean = cache[parentType];
    if (cachedResult || cachedResult === false) {
      return cachedResult;
    }

    const childTypes: (FragmentType | EquationType)[] = this.getChildTypes(parentType);

    if (!childTypes.length) {
      cache[parentType] = false;
      return false;
    } else if (childTypes.includes(childType)) {
      cache[parentType] = true;
      return true;
    }

    for (const type of childTypes) {
      if (!checking.includes(type)) {
        const isValidAnchestor: boolean = TreeStructureValidator.isValidAncestorType(
          type,
          childType,
          cache,
          checking.concat([type])
        );
        if (isValidAnchestor) {
          cache[parentType] = true;
          return true;
        }
      }
    }

    cache[parentType] = false;
    return false;
  }

  private static getChildTypes(parentType: FragmentType | EquationType) {
    switch (parentType) {
      case EquationType.INLINE:
        return [];
      case EquationType.DISPLAY:
        return TreeStructureValidator.VALID_CHILDREN.get(FragmentType.EQUATION).types;
      default:
        return TreeStructureValidator.VALID_CHILDREN.get(parentType).types;
    }
  }

  private static hasValidChildCount(fragment: Fragment, logErrors: boolean = false): boolean {
    if (!fragment) {
      return true;
    }

    const allowed: ChildCount = TreeStructureValidator.VALID_CHILDREN.get(fragment.type).count;
    const actual: number = fragment.children.length;
    let hasValidChildCount: boolean = true;

    switch (allowed) {
      case ChildCount.NONE: {
        hasValidChildCount = actual === 0;
        break;
      }
      case ChildCount.ZERO_OR_ONE: {
        hasValidChildCount = actual <= 1;
        break;
      }
      case ChildCount.AT_LEAST_ONE: {
        hasValidChildCount = actual >= 1;
        break;
      }
      case ChildCount.ANY: {
        hasValidChildCount = true;
        break;
      }
    }

    if (!hasValidChildCount && logErrors) {
      Logger.error(
        'tree-structure-error',
        'invalid tree structure : ' + FragmentType[fragment.type] + ' had child count of ' + actual
      );
    }

    return hasValidChildCount;
  }
}
